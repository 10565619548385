<template>
    <div class="videoStatistics">

        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="视频数据" name="1">
                <ul class="videoStatisticsCon">
                    <li>
                        <p>视频播放 <em>{{listData.playNum}}</em> </p>
                        <p>昨日 <span>{{listData.playNum4Yes}}</span></p>
                    </li>
                    <li>
                        <p>评论数 <em>{{listData.commentNum}}</em> </p>
                        <p>昨日 <span>{{listData.commentNum4Yes}}</span></p>
                    </li>
                    <li>
                        <p>点赞数 <em>{{listData.goodNum}}</em> </p>
                        <p>昨日 <span>{{listData.goodNum4Yes}}</span></p>
                    </li>
                    <li>
                        <p>弹幕数 <em>{{listData.bulletNum}}</em> </p>
                        <p>昨日 <span>{{listData.bulletNum4Yes}}</span></p>
                    </li>
                    <li>
                        <p>分享数 <em>{{listData.transferNum}}</em> </p>
                        <p>昨日 <span>{{listData.transferNum4Yes}}</span></p>
                    </li>
                    <li>
                        <p>收藏数 <em>{{listData.collectNum}}</em> </p>
                        <p>昨日 <span>{{listData.collectNum4Yes}}</span></p>
                    </li>
                    <li>
                        <p>打赏数 <em>{{listData.giftNum}}</em> </p>
                        <p>昨日 <span>{{listData.giftNum4Yes}}</span></p>
                    </li>
                </ul>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import {
        statistics
    } from '@/api/authority'
    export default {
        name: "videoStatistics",
        data() {
            return {
                activeName: '1',
                listData: {}
            };
        },
        mounted() {
            this.getStatistics()
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },
            // 获取数据
            getStatistics() {
                statistics().then(response => {
                    if (response.data.code == 0) {
                        this.listData = response.data.data
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            }
        }
    }
</script>
<style lang="less">
    .videoStatistics {
        .videoStatisticsCon {
            margin: 24px 0px 0px -17px;

            li {
                width: 286px;
                height: 101px;
                background-color: #f7fcfe;
                padding: 4px 0px 0px 37px;
                box-sizing: border-box;
                font-size: 12px;
                float: left;
                margin: 12px 0px 0px 17px;

                p {
                    font-size: 12px;
                    color: #999999;
                    padding-top: 15px;

                    em {
                        display: block;
                        font-style: normal;
                        padding: 10px 0px 0px 0px;
                    }

                    em,
                    span {
                        color: #06a3d7;
                    }
                }
            }
        }
    }
</style>